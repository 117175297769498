import {
  COUNTRY_AGGREGATE,
  REMAINING_RESERVES,
  barChartAggregateTypes,
  barGraphTypes,
  graphTitles,
  graphTypeChildren,
} from '../utils';
import { DEFAULT_TRACE_LIMIT, TOP_SORT_DIRECTION } from '../../../../components/Graphs/constants';
import React, { createContext, useEffect, useState } from 'react';

import { GOOGLE_ANALYTICS_TAG } from '../../constants';
import { GRAPH_ASSETS_QUERY_NEW } from '../operations';
import GraphControls from '../../../../components/Graphs/GraphControls';
import { cloneDeep } from 'lodash';
import { downloadXlsx } from '../../../../components/Downloads/helpers';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

const BarGraphContextLeft = createContext();

export const BarGraphProviderLeft = ({ children, portfolios }) => {
  const [openControlsModal, setOpenControlsModal] = useState(false);

  const [aggregate, setAggregate] = useState(COUNTRY_AGGREGATE);
  const [graphType, setGraphType] = useState(REMAINING_RESERVES);
  const [graphTypeOptions, setGraphTypeOptions] = useState([]);
  const [limit, setLimit] = useState(DEFAULT_TRACE_LIMIT);
  const [sortDirection, setSortDirection] = useState(TOP_SORT_DIRECTION);
  const [range, setRange] = useState({ x: [], y: [] });
  const [modalRange, setModalRange] = useState({ x: [], y: [] });
  const [showPlotlyLegend, setShowPlotlyLegend] = useState(true);

  const resetRange = () => {
    setRange({ x: [], y: [] });
    setModalRange({ x: [], y: [] });
  };

  const onRelayout = (e) => {
    const x = [e['xaxis.range[0]'], e['xaxis.range[1]']];
    const y = [e['yaxis.range[0]'], e['yaxis.range[1]']];
    setRange({ x, y });
  };

  const onGraphUpdate = () => {
    if (
      (range.x[0] !== modalRange.x[0] && range.x[1] !== modalRange.x[1]) ||
      (range.y[0] !== modalRange.y[0] && range.y[1] !== modalRange.y[1])
    ) {
      setModalRange(range);
    }
  };
  // TODO: permanently replace old queries with new ones
  const { data, loading } = useQuery(GRAPH_ASSETS_QUERY_NEW, {
    variables: {
      aggregates: [aggregate.name],
      chartType: 'bar',
      graphType: graphType.value,
      graphTypeOptions: graphTypeOptions.map((option) => option.name),
      portfolios,
      limit: parseInt(limit),
      sortDirection: sortDirection.value,
    },
  });

  useEffect(() => {
    resetRange();
  }, [data]);

  const downloadCallback = (errorHandler, successHandler) => {
    const path = 'portfolio-analysis/export-asset-bar-graph-data/';
    const body = {
      aggregate: aggregate.name,
      graphType: graphType.value,
      graphTypeOptions: graphTypeChildren[graphType.value]?.map((option) => option.name) || [],
      portfolios,
      limit,
      sortDirection: sortDirection.value,
    };
    const downloadName = `${portfolios[0].name}_Asset_Data_by_${aggregate.name}.xlsx`;

    downloadXlsx(path, body, downloadName, errorHandler, successHandler);
  };

  const trace = useMemo(() => {
    let analytics = data?.graphAssetsNew?.analytics || [];
    // Workaround for bug where the chart will not display if the marker color is set:
    return cloneDeep(analytics);
  }, [data]);

  return (
    <BarGraphContextLeft.Provider
      value={{
        downloadCallback,
        graphType,
        loading,
        modalRange,
        onGraphUpdate,
        onRelayout,
        range,
        setModalRange,
        setOpenControlsModal,
        setShowPlotlyLegend,
        showPlotlyLegend,
        showPlotlyLegendToggle: !!graphTypeChildren[graphType.value] && graphTypeOptions.length > 0,
        sortDirection,
        trace,
        maxY: data?.graphAssetsNew?.maxY || [],
        googleAnalyticsTag: GOOGLE_ANALYTICS_TAG,
        graphTitles,
      }}
    >
      {children}
      <GraphControls
        aggregate={aggregate}
        aggregateTypes={barChartAggregateTypes}
        googleAnalyticsTag={GOOGLE_ANALYTICS_TAG}
        graphType={graphType}
        graphTypeChildren={graphTypeChildren}
        graphTypeOptions={graphTypeOptions}
        includeOptions
        limit={limit}
        open={openControlsModal}
        setAggregate={setAggregate}
        setGraphType={setGraphType}
        setGraphTypeOptions={setGraphTypeOptions}
        setLimit={setLimit}
        setOpen={setOpenControlsModal}
        setSortDirection={setSortDirection}
        sortDirection={sortDirection}
        types={barGraphTypes}
      />
    </BarGraphContextLeft.Provider>
  );
};

export const BarGraphConsumerLeft = BarGraphContextLeft.Consumer;

export default BarGraphContextLeft;
