import {
  DEFAULT_MAP_ZOOM,
  MAP_LAYER_IDS,
  MAP_SOURCE_IDS,
  PIPELINE_NETWORK_MAP_ZOOM,
  SS_HEIGHT,
  SS_WIDTH,
} from '../../../components/Map/Utils/constants';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { getLatLongFromPoint, snakeToCamel } from '../../../utils/helpers';

import AssetModels from './assetModels';
import AssociatedAssets from './associatedAssets';
import AssociatedPipelines from './associatedPipelines';
import { Button } from '@mui/material';
import { CameraAlt } from '@mui/icons-material';
import EaWellTracker from './eaWellTracker.js';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Info from './info';
import Loading from '../../../components/Loading';
import PipelineNetworks from './pipelineNetworks';
import RelatedExplorations from './relatedExplorations';
import Reports from './reports';
import Slide from '@mui/material/Slide';
import SnackbarContext from '../../../contexts/SnackbarContext';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import clsx from 'clsx';
import { getQuery } from './operations';
import makeStyles from '@mui/styles/makeStyles';
import { pluralizeEntityType } from '../../../components/Map/Utils/utils';
import { useFeatureFlags } from '../../../contexts/FeatureFlagsContext';
import { useQuery } from '@apollo/client';
import { OIL_AND_GAS_MAP_SUBSCRIPTION } from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  info: {
    ...theme.mapPanel,
    backgroundColor: 'white',
    padding: '5px',
    width: '400px',
    right: 0,
    height: `calc(${theme.sizes.fullPage.height} - 100px)`,
    transition: 'width 0.4s ease-in-out',
    [theme.breakpoints.down('lg')]: {
      width: '325px',
    },
  },
  wider: {
    width: '900px',
    [theme.breakpoints.down('lg')]: {
      width: '725px',
    },
    [theme.breakpoints.down(1120)]: {
      width: '525px',
    },
    [theme.breakpoints.down(800)]: {
      width: '400px',
    },
  },
  infoShrink: {
    height: `calc(${theme.sizes.fullPage.height} - 85px)`,
  },
  scroll: {
    height: '100%',
    overflowY: 'auto',
    ...theme.palette.scrollbar,
  },
  tab: {
    backgroundColor: 'rgba(0,0,0,.2)',
    border: 'solid thin rgba(0, 0, 0, .5)',
    color: 'rgba(0, 0, 0, .7)',
    minWidth: '50px',
    minHeight: '30px',
    '&.Mui-selected': {
      backgroundColor: 'rgba(236, 240, 245, 0)',
      color: '#2a2e49',
      borderBottom: 'none',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px',
    },
  },
  tabContainer: {
    margin: '0 5px',
    minHeight: '30px',
  },
  indicator: {
    display: 'none',
  },
  flexContainer: {
    height: '100%',
  },
  button: {
    ...theme.palette.button,
    width: '33%',
    margin: theme.spacing(1),
    justifyContent: 'space-evenly',
  },
}));

// Ru says this list is unlikely to change so hardcoding for now
// while we think of a better solution to handle this
// TODO: Think of how we handle countries that we enable this for
// List is based on countries with well production with some exceptions
const wellByWellProductionDataActive = {
  ARG: true,
  ALK: true,
  BEN: true,
  BRA: true,
  CA1: true,
  COL: true,
  GBR: true,
  MEX: true,
  NLD: true,
  NOR: true,
  PER: true,
  USA: true,
};

export default ({
  isOpen,
  item,
  layerName,
  layerCountry,
  map,
  prevItemRef,
  toggleScreenshotModeDrawer,
  showParticipationHistory,
  setShowParticipationHistory,
  shrink,
}) => {
  const classes = useStyles();
  const scrollbarRef = useRef(null);
  const { screenshotMode } = useFeatureFlags();

  const [tab, setTab] = useState('information');
  const [skipQuery, setSkipQuery] = useState(true);
  const [displayedItem, setDisplayedItem] = useState(item);
  const itemLegacyId = item?.legacyId;
  const [queriedItem, setQueriedItem] = useState({
    id: item?.id,
    legacyId: itemLegacyId,
    entityType: item?.entityType,
    countryIsoCode: layerCountry,
  });

  const { setSnack } = useContext(SnackbarContext);

  const handleTabChange = (_event, newTab) => {
    setTab(newTab);
  };

  const { loading, data } = useQuery(getQuery(queriedItem.entityType), {
    skip:
      skipQuery || !(queriedItem.id || queriedItem.legacyId) || layerName === MAP_LAYER_IDS.basins,
    fetchPolicy: 'network-only',
    variables: {
      id: queriedItem.id,
      legacyId: queriedItem.legacyId,
      countryIsoCode: queriedItem.countryIsoCode || layerCountry,
      feature: OIL_AND_GAS_MAP_SUBSCRIPTION,
    },
    onCompleted: (data) => {
      const { asset, block, pipelineNetwork } = data;

      // Updated displayedItem
      if (asset) {
        setDisplayedItem({
          countryIsoCode: displayedItem.countryIsoCode,
          entityType: 'asset',
          hydrocarbonType: asset.hydrocarbonType,
          id: asset.id,
          legacyId: asset.legacyId,
          displayName: asset.displayName,
          name: asset.name,
          status: asset.status,
          countryName: asset.country.name,
          boundingBox: asset.boundingBox,
        });
      } else if (block) {
        setDisplayedItem({
          countryIsoCode: displayedItem.countryIsoCode,
          entityType: 'block',
          hydrocarbonType: block.hydrocarbonType,
          id: block.id,
          legacyId: block.legacyId,
          displayName: block.displayName,
          name: block.name,
          status: block.blockStatus?.name,
          countryName: block.country.name,
        });
      } else if (pipelineNetwork) {
        setDisplayedItem({
          entityType: 'pipeline_network',
          legacyId: null,
          ...pipelineNetwork,
        });
      } else {
        let entityType = snakeToCamel(item.entityType);
        if (data[entityType]) {
          setDisplayedItem({
            ...item,
            id: data[entityType].id,
            legacyId: data[entityType].legacyId,
          });
        } else {
          entityType = snakeToCamel(Object.keys(data)[0]);
          setDisplayedItem({
            ...data[entityType],
            entityType: entityType,
            id: data[entityType].id,
            legacyId: data[entityType].legacyId,
          });
        }
      }

      // Update pipeline network source for highlighting
      const geojsonSource = map.getSource(MAP_SOURCE_IDS.pipelineNetworks);
      if (pipelineNetwork) {
        geojsonSource.setData(
          pipelineNetwork?.aggregatedGeojson
            ? pipelineNetwork?.aggregatedGeojson
            : { type: 'FeatureCollection', features: [] },
        );
        map.flyTo({
          center: getLatLongFromPoint(pipelineNetwork?.geom),
          zoom: PIPELINE_NETWORK_MAP_ZOOM,
        });
      } else {
        geojsonSource.setData({ type: 'FeatureCollection', features: [] });
      }
    },
    onError: (error) => {
      setSnack({
        message: error.message,
        severity: 'error',
        open: true,
      });
    },
  });

  const isQueryable = (entityType) => {
    if (!entityType) return false;

    const queryableEntities = [
      'asset',
      'block',
      'ea_well',
      'facility',
      'field',
      'formation',
      'lng_project',
      'presalt_polygon',
      'lease',
      'pipeline',
      'pipeline_network',
      'pipeline_segment',
      'well',
    ];
    return queryableEntities.includes(entityType);
  };

  // when the selected item changes
  useEffect(() => {
    // depending on the item, run the query
    if (isQueryable(item?.entityType)) {
      // if selected item is valid and queryable, then query
      setQueriedItem({
        id: item.id,
        legacyId: item.legacyId,
        entityType: item.entityType,
        countryIsoCode: layerCountry,
      });
      setSkipQuery(false);
    } else {
      // else reset query item and skip query
      setQueriedItem({
        id: null,
        legacyId: null,
        entityType: null,
        countryIsoCode: null,
      });
      setSkipQuery(true);
    }

    // only set the displayed item if the selected item is different
    if (
      item !== null &&
      (displayedItem === null ||
        layerName === MAP_LAYER_IDS.basins ||
        item.entityType !== displayedItem.entityType ||
        (parseInt(item.legacyId) !== parseInt(displayedItem.legacyId) &&
          item.countryIsoCode !== displayedItem.countryIsoCode))
    ) {
      setDisplayedItem(item);
    }

    // scroll info panel to top
    if (scrollbarRef.current) {
      scrollbarRef.current.scrollTop = 0;
    }

    // if the item is a well, default the tab to the information tab
    if (item?.entityType === 'well' && tab !== 'information') {
      setTab('information');
    }
  }, [item]);

  const handleQueriedItemChange = (value) => {
    if (!value) return;
    const { id, legacyId, type, country, geom } = value;
    const didUpdateLegacyId = parseInt(legacyId) !== parseInt(displayedItem.legacyId);

    if (didUpdateLegacyId) {
      setQueriedItem({
        id,
        legacyId,
        entityType: type,
        countryIsoCode: country?.isoCode || layerCountry,
        geom,
      });
      setSkipQuery(false);
    }
  };

  // Update highlighting and position when queriedItem changes
  // Occurs with the "Asset Models" and "Related Exploration" dropdown features
  useEffect(() => {
    let queriedMapItem = {
      layerCountry: queriedItem?.countryIsoCode || layerCountry,
      properties: {
        ...queriedItem,
        legacyId: queriedItem?.legacyId ? parseInt(queriedItem.legacyId) : null,
      },
      sourceLayer: queriedItem?.entityType
        ? pluralizeEntityType(queriedItem.entityType)
        : layerName,
    };
    const prevItem = prevItemRef.current;
    const layer = queriedMapItem
      ? `${queriedMapItem.layerCountry}_${queriedMapItem.sourceLayer}`
      : null;
    if (prevItem !== null && prevItem !== undefined) {
      // remove previous layer highlight
      map.removeLayerHighlight(prevItem, layer);
    }
    if (queriedMapItem?.properties?.legacyId) {
      // highlight queriedMapItem
      map.addLayerHighlight(queriedMapItem, prevItemRef, layer);
    }

    // fly to geom if available
    if (queriedItem.geom) {
      map.flyTo({
        center: getLatLongFromPoint(queriedItem.geom),
        zoom: DEFAULT_MAP_ZOOM,
      });
    }
  }, [queriedItem]);

  // TOP SECTION ENTITIES
  const renderAssetInfo = () => (
    <>
      <ErrorBoundary>
        <AssetModels
          displayedItem={displayedItem}
          loading={loading}
          data={data}
          onChange={handleQueriedItemChange}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <RelatedExplorations
          displayedItem={displayedItem}
          loading={loading}
          data={data}
          onChange={handleQueriedItemChange}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <PipelineNetworks
          displayedItem={displayedItem}
          loading={loading}
          data={data}
          onChange={handleQueriedItemChange}
        />
      </ErrorBoundary>
      {displayedItem.status === 'live' && (
        <ErrorBoundary>
          <Reports displayedItem={displayedItem} />
        </ErrorBoundary>
      )}
    </>
  );

  const renderBlockInfo = () => (
    <>
      <ErrorBoundary>
        <RelatedExplorations
          displayedItem={displayedItem}
          loading={loading}
          data={data}
          onChange={handleQueriedItemChange}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <Reports displayedItem={displayedItem} />
      </ErrorBoundary>
    </>
  );

  const renderPipelineInfo = () => (
    <>
      <ErrorBoundary>
        <PipelineNetworks
          displayedItem={displayedItem}
          loading={loading}
          data={data}
          onChange={handleQueriedItemChange}
        />
      </ErrorBoundary>
    </>
  );

  const renderPipelineNetworkInfo = () => (
    <>
      <ErrorBoundary>
        <AssociatedAssets
          displayedItem={displayedItem}
          loading={loading}
          data={data}
          onChange={handleQueriedItemChange}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <AssociatedPipelines
          displayedItem={displayedItem}
          loading={loading}
          data={data}
          onChange={handleQueriedItemChange}
        />
      </ErrorBoundary>
    </>
  );

  const isThereProdDataForWell = useMemo(() => {
    if (!data?.well) return false;
    const { well } = data;
    const prodData =
      well.ipLiquidsBbld ||
      well.ipGasMmcfd ||
      well.eurLiquidsMmbbl ||
      well.eurGasBcf ||
      well.gorScfBbl ||
      well.recent3MonthWaterCutPct ||
      well.recentProductionLiquidsBbld ||
      well.recentProductionGasMmcfd;
    return !!prodData;
  }, [data]);

  const renderWellInfo = () => {
    if (!wellByWellProductionDataActive[layerCountry]) return null;

    return (
      <Tabs
        className={classes.tabContainer}
        classes={{ flexContainer: classes.flexContainer }}
        TabIndicatorProps={{
          classes: {
            root: classes.indicator,
          },
        }}
        variant="fullWidth"
        scrollButtons="auto"
        value={tab}
        onChange={handleTabChange}
      >
        <Tab className={classes.tab} value="information" label="Information" />
        {isThereProdDataForWell && (
          <Tab className={classes.tab} value="productionData" label="Production Data" />
        )}
      </Tabs>
    );
  };

  const renderEaWellInfo = () => (
    // Add a download button to the E&A Well Tracker file:
    <ErrorBoundary>
      <EaWellTracker eaWell={data.eaWell} />
    </ErrorBoundary>
  );

  const renderTopSection = () => {
    switch (displayedItem?.entityType) {
      case 'asset':
        return renderAssetInfo();
      case 'block':
        return renderBlockInfo();
      case 'well':
        return renderWellInfo();
      case 'ea_well':
        return renderEaWellInfo();
      case 'pipeline':
        return renderPipelineInfo();
      case 'pipeline_network':
        return renderPipelineNetworkInfo();
      default:
        return null;
    }
  };

  // DETAILS SECTION INCLUDING GRAPHS
  const renderDetails = (showParticipationHistory, setShowParticipationHistory) => (
    <ErrorBoundary>
      <Info
        layerCountry={layerCountry}
        layerName={layerName}
        item={displayedItem || item}
        setShowParticipationHistory={setShowParticipationHistory}
        showParticipationHistory={showParticipationHistory}
        data={data}
        tab={wellByWellProductionDataActive[layerCountry] ? tab : 'information'}
        changeQueriedItem={handleQueriedItemChange}
      />
    </ErrorBoundary>
  );

  const openScreenshotModal = useCallback(() => {
    toggleScreenshotModeDrawer();
    if (displayedItem.boundingBox) {
      const paddingIdx = 250;
      map.fitBounds(displayedItem.boundingBox, {
        padding: {
          top: paddingIdx,
          bottom: window.innerHeight - SS_HEIGHT + paddingIdx,
          left: paddingIdx,
          right: window.innerWidth - SS_WIDTH + paddingIdx,
        },
      });
    }
  }, [toggleScreenshotModeDrawer, map, displayedItem]);

  const hasPermissions = () => {
    return displayedItem?.entityType === 'asset' && screenshotMode;
  };

  const renderScreenshotButton = () => {
    return (
      hasPermissions() && (
        <Button
          id="screenshot-modal-close"
          className={classes.button}
          onClick={openScreenshotModal}
          variant="contained"
        >
          <CameraAlt fontSize="small" color="primary" className={classes.orangeIcon} />
          <div id="screenshot-button" className={classes.label}>
            screenshot mode
          </div>
        </Button>
      )
    );
  };

  return (
    <Slide
      direction="left"
      in={isOpen}
      mountOnEnter
      unmountOnExit
      className={clsx(classes.info, {
        [classes.wider]: showParticipationHistory,
        [classes.infoShrink]: shrink,
      })}
    >
      <div>
        {layerName === MAP_LAYER_IDS.basins || (!loading && item && data) ? (
          <div ref={scrollbarRef} className={classes.scroll}>
            {renderTopSection()}
            {renderDetails(showParticipationHistory, setShowParticipationHistory)}
            {renderScreenshotButton(item)}
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </Slide>
  );
};
