import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid2';
import MenuButton from '../../MenuButton';
import React from 'react';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { NO_AGGREGATE_LAYER, SORT_DIRECTIONS } from '../constants';
import InputItem from '../../InputList/inputItem';
import { Checkbox } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  aggregateLayer: {
    borderRight: 'solid thin rgba(0, 0, 0, 0.12)',
    '&:last-child': {
      border: 'none',
    },
  },
  checkboxRoot: {
    width: '100%',
  },
  checkboxRowLabel: {
    width: '100%',
    justifyContent: 'space-between',
    margin: 0,
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.26)',
  },
  inputItemRoot: {
    width: 'fit-content',
    '& > .MuiOutlinedInput-root': {
      fontSize: '0.7rem',
      width: '55px',
    },
  },
  label: {
    ...theme.palette.alternateText,
    fontWeight: 'bold',
  },
  layerLabel: {
    width: '100%',
    borderBottom: 'solid thin rgba(0, 0, 0, 0.12)',
  },
  radioRow: {
    width: '100%',
    justifyContent: 'space-between',
    margin: 0,
  },
  radioRowLabel: {
    fontWeight: 'bold',
  },
}));

export default ({
  aggregateLayers,
  aggregateTypes,
  checkboxAggregateTypes,
  handleSetAggregate,
  handleSetState,
  itemIsDisabledParams = {},
  selectedAggregate,
  selectedCheckboxAggregates,
  selectedLimit,
  selectedSortDirection,
}) => {
  const classes = useStyles();
  // Disabled function for treemap layer aggregates
  const isAggregateLayerDisabled = (aggregate, layer) => {
    // Check if graph type is compatible with the aggregate
    const isDisabled = aggregate.isDisabled && aggregate.isDisabled(itemIsDisabledParams);
    // If not, disable button and uncheck
    if (isDisabled) {
      if (selectedAggregate[layer].name === aggregate.name) {
        handleSetState(NO_AGGREGATE_LAYER, 'aggregate', layer);
      }
      return true;
    }
    // Check if aggregate is already selected in another layer
    const found = Object.values(selectedAggregate).findIndex((a) => a.name === aggregate.name);
    return (
      aggregate.name !== NO_AGGREGATE_LAYER.name &&
      found !== -1 &&
      Object.keys(selectedAggregate)[found] !== layer
    );
  };

  const aggregateIsDisabled = (aggregate) =>
    aggregate.isDisabled && aggregate.isDisabled(itemIsDisabledParams);

  const aggregateOnChange = (newAggregate) => {
    if (newAggregate.options) {
      const newLimit = newAggregate.defaultLimit || selectedLimit;
      handleSetAggregate(newAggregate, newLimit, selectedSortDirection);
    } else {
      handleSetAggregate(newAggregate);
    }
  };

  const sortDirectionOnChange = (newSort) => {
    let hasNewLimit = selectedLimit;
    if (newSort.limit !== undefined) {
      hasNewLimit = newSort.limit;
    }
    handleSetAggregate(selectedAggregate, hasNewLimit, newSort);
  };

  const limitOnChange = (e) => {
    // Only allow postive integers and null string
    if (e.target.value.match(/^(\d|\|)*$/)) {
      handleSetAggregate(selectedAggregate, e.target.value, selectedSortDirection);
    }
  };

  const checkboxAggregateSelected =
    selectedCheckboxAggregates &&
    Object.values(selectedCheckboxAggregates).filter((a) => a).length !== 0;

  return (
    <>
      {aggregateTypes && (
        <FormControl style={{ width: '100%', marginBottom: '5px' }}>
          <Typography className={classes.label} variant="overline">
            Aggregate Data
          </Typography>
          {/* This renders the aggregate layer option seen on the portfolio analysis treemap */}
          {aggregateLayers ? (
            <Grid container direction="row" spacing={1}>
              {aggregateLayers.map((layer, i) => (
                <Grid
                  className={classes.aggregateLayer}
                  container
                  direction="column"
                  key={`graph-aggregate-radio-layer-${layer}`}
                  size={4}
                >
                  <Typography className={classes.layerLabel} variant="overline">
                    {layer}
                  </Typography>
                  <MenuButton
                    context="aggregate-dropdown"
                    items={i === 0 ? aggregateTypes : [NO_AGGREGATE_LAYER, ...aggregateTypes]}
                    isOptionDisabled={(aggregate) => isAggregateLayerDisabled(aggregate, layer)}
                    selectedItem={selectedAggregate[layer]}
                    onChange={(aggregate) => handleSetState(aggregate, 'aggregate', layer)}
                    tooltip="Select which criteria to aggregate the data by."
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid
              container
              direction="row"
              sx={{
                alignItems: 'center',
              }}
              spacing={1}
            >
              {/* This renders the aggregate dropdown */}
              <MenuButton
                context="aggregate-dropdown"
                items={aggregateTypes}
                isDisabled={checkboxAggregateSelected}
                isOptionDisabled={aggregateIsDisabled}
                selectedItem={selectedAggregate}
                onChange={aggregateOnChange}
                tooltip="Select which criteria to aggregate the data by."
              />
              {/* If the selected aggregate has options enabled (e.g. limit, sort direction), enable them */}
              <MenuButton
                context={selectedAggregate.label}
                items={SORT_DIRECTIONS}
                isDisabled={!selectedAggregate.options || checkboxAggregateSelected}
                selectedItem={selectedSortDirection}
                onChange={sortDirectionOnChange}
                tooltip="Select whether you want the top or bottom x results."
              />
              <InputItem
                styles={classes}
                inputLabel="Limit"
                value={selectedLimit}
                onChange={limitOnChange}
                isDisabled={
                  !selectedAggregate.options ||
                  selectedSortDirection.limit !== undefined ||
                  checkboxAggregateSelected
                } // All sort direction has a default limit of 0
              />
              {/* This renders aggregate checkboxes (can select multiple) */}
              {checkboxAggregateTypes &&
                checkboxAggregateTypes.map((aggregate) => (
                  <FormControl
                    key={`checkbox-aggregates-${aggregate.name}`}
                    component="fieldset"
                    className={classes.checkboxRoot}
                  >
                    <FormControlLabel
                      className={classes.checkboxRowLabel}
                      labelPlacement="start"
                      value={true}
                      control={
                        <Checkbox
                          color="primary"
                          checked={selectedCheckboxAggregates[aggregate]}
                          onClick={() =>
                            handleSetState(
                              {
                                ...selectedCheckboxAggregates,
                                [aggregate.name]: !selectedCheckboxAggregates[aggregate.name],
                              },
                              'checkboxAggregates',
                            )
                          }
                        />
                      }
                      label={
                        <Typography className={classes.radioRowLabel}>{aggregate.name}</Typography>
                      }
                      disabled={aggregateIsDisabled(aggregate)}
                    />
                  </FormControl>
                ))}
            </Grid>
          )}
        </FormControl>
      )}
    </>
  );
};
