import {
  ASSET_AGGREGATE,
  COMPANY_AGGREGATE,
  REMAINING_RESERVES,
  defaultAggregateTypes,
  graphTitles,
  treeGraphTypeChildren,
  treeMapTypes,
} from '../utils';
import React, { createContext, useState } from 'react';

import { GOOGLE_ANALYTICS_TAG } from '../../constants';
import { GRAPH_ASSETS_QUERY_NEW } from '../operations';
import GraphControls from '../../../../components/Graphs/GraphControls';
import { downloadXlsx } from '../../../../components/Downloads/helpers';
import { useQuery } from '@apollo/client';
import { NO_AGGREGATE_LAYER } from '../../../../components/Graphs/constants';

const TreemapContext = createContext();

export const TreemapProvider = ({ children, portfolios }) => {
  const [openControlsModal, setOpenControlsModal] = useState(false);

  const [aggregate, setAggregate] = useState({
    'Layer One': COMPANY_AGGREGATE,
    'Layer Two': ASSET_AGGREGATE,
    'Layer Three': NO_AGGREGATE_LAYER,
  });
  const [graphType, setGraphType] = useState(REMAINING_RESERVES);
  const [graphTypeOptions, setGraphTypeOptions] = useState([]);
  const [zoomedNode, setZoomedNode] = useState('');
  // TODO: permanently replace old queries with new ones
  const { data, loading } = useQuery(GRAPH_ASSETS_QUERY_NEW, {
    variables: {
      aggregates: Object.values(aggregate)
        .filter((a) => !!a.name)
        .map((a) => a.name),
      chartType: 'treemap',
      graphType: graphType.value,
      graphTypeOptions: graphTypeOptions.map((option) => option.name),
      portfolios,
    },
  });

  const downloadCallback = (errorHandler, successHandler) => {
    const path = 'portfolio-analysis/export-asset-treemap-data/';
    const body = {
      graphType: graphType.value,
      graphTypeOptions: treeGraphTypeChildren[graphType.value]?.map((option) => option.name) || [],
      portfolios,
    };
    const downloadName = `${portfolios[0].name}_Asset_${graphType.label}_Data.xlsx`;

    downloadXlsx(path, body, downloadName, errorHandler, successHandler);
  };

  const onClick = ({ points }) => {
    const node = points[0];
    if (node && node.id) {
      setZoomedNode(node.id);
    } else {
      setZoomedNode('');
    }
  };

  const title = `${graphType.label} ${graphTitles[graphType.value]}`;

  return (
    <TreemapContext.Provider
      value={{
        title,
        setOpenControlsModal,
        labels: data?.graphAssetsNew?.analytics[0].labels || [],
        parents: data?.graphAssetsNew?.analytics[0].parents || [],
        values: data?.graphAssetsNew?.analytics[0].values || [],
        customdata: data?.graphAssetsNew?.analytics[0].customdata || [],
        ids: data?.graphAssetsNew?.analytics[0].ids || [],
        hovertemplate: data?.graphAssetsNew?.analytics[0].hovertemplate || '',
        texttemplate: data?.graphAssetsNew?.analytics[0].texttemplate || [],
        loading,
        downloadCallback,
        onClick,
        zoomedNode,
        googleAnalyticsTag: GOOGLE_ANALYTICS_TAG,
      }}
    >
      {children}
      <GraphControls
        aggregate={aggregate}
        aggregateTypes={defaultAggregateTypes}
        aggregateLayers={Object.keys(aggregate)}
        googleAnalyticsTag={GOOGLE_ANALYTICS_TAG}
        graphType={graphType}
        graphTypeChildren={treeGraphTypeChildren}
        graphTypeOptions={graphTypeOptions}
        includeOptions
        open={openControlsModal}
        setAggregate={setAggregate}
        setGraphType={setGraphType}
        setGraphTypeOptions={setGraphTypeOptions}
        setOpen={setOpenControlsModal}
        types={treeMapTypes}
      />
    </TreemapContext.Provider>
  );
};

export const TreemapConsumer = TreemapContext.Consumer;

export default TreemapContext;
