import React, { createContext, useCallback, useContext, useMemo } from 'react';

import { GET_FEATURE_FLAGS_QUERY } from './operations';
import { isAuthenticated } from '../../utils/auth';
import { useCurrentUser } from '../CurrentUserContext';
import { useQuery } from '@apollo/client';

const FeatureFlagsContext = createContext();

const THIRTY_MINUTES = 1000 * 60 * 30;

const FEATURE_FLAGS = {
  IN_DEVELOPMENT: 'inDevelopment',
  IN_LOCAL_ENV: 'inLocalEnv',
  ENABLE_SENTRY: 'enableSentry',
  SCREENSHOT_MODE: 'screenshotMode',
  DISABLE_CCF_LIMIT: 'disableCcfLimit',
  MAPBOX_POC: 'mapboxPoc',
  MA_OPPORTUNITIES_TAB: 'maOpportunitiesTab',
  GEOLOGY_FILTER: 'geologyFilter',
  PDF_EMBEDDING: 'pdfEmbedding',
  PA_HIDE_COUNTRIES_WITH_NO_ASSETS: 'paHideCountriesWithNoAssets',
  SMART_REPORTS: 'smartReports',
  ENABLE_ASSET_REPORT_VIEW_MODE: 'enableAssetReportViewMode',
  FEEDBACK_FEATURE: 'feedbackFeature',
  GLOBAL_DOWNLOADS: 'globalDownloads',
};

const FeatureFlagsProvider = ({ children }) => {
  const { currentUser } = useCurrentUser();
  const { data, loading } = useQuery(GET_FEATURE_FLAGS_QUERY, {
    skip: !Object.keys(currentUser).length || !isAuthenticated(),
    pollInterval: THIRTY_MINUTES,
  });
  const { featureFlags } = data || { featureFlags: null };
  const isFlagEnabled = useCallback((flagKey) => featureFlags?.[flagKey] ?? false, [featureFlags]);

  const flagsLoaded = useMemo(() => !loading && featureFlags, [loading, featureFlags]);

  // Feature Flags
  const inDevelopment = useMemo(() => isFlagEnabled(FEATURE_FLAGS.IN_DEVELOPMENT, []));
  const inLocalEnv = useMemo(() => isFlagEnabled(FEATURE_FLAGS.IN_LOCAL_ENV, []));
  const enableSentry = useMemo(() => isFlagEnabled(FEATURE_FLAGS.ENABLE_SENTRY, []));
  const screenshotMode = useMemo(() => isFlagEnabled(FEATURE_FLAGS.SCREENSHOT_MODE, []));
  const disableCcfLimit = useMemo(() => isFlagEnabled(FEATURE_FLAGS.DISABLE_CCF_LIMIT, []));
  const mapboxPoc = useMemo(() => isFlagEnabled(FEATURE_FLAGS.MAPBOX_POC, []));
  const maOpportunitiesTab = useMemo(() => isFlagEnabled(FEATURE_FLAGS.MA_OPPORTUNITIES_TAB, []));
  const geologyFilter = useMemo(() => isFlagEnabled(FEATURE_FLAGS.GEOLOGY_FILTER, []));
  const pdfEmbedding = useMemo(() => isFlagEnabled(FEATURE_FLAGS.PDF_EMBEDDING, []));
  const paHideCountriesWithNoAssets = useMemo(() =>
    isFlagEnabled(FEATURE_FLAGS.PA_HIDE_COUNTRIES_WITH_NO_ASSETS, []),
  );
  const smartReports = useMemo(() => isFlagEnabled(FEATURE_FLAGS.SMART_REPORTS, []));
  const enableAssetReportPdfViewMode = useMemo(() =>
    isFlagEnabled(FEATURE_FLAGS.ENABLE_ASSET_REPORT_VIEW_MODE, []),
  );
  const feedbackFeature = useMemo(() => isFlagEnabled(FEATURE_FLAGS.FEEDBACK_FEATURE, []));
  const globalDownloads = useMemo(() => isFlagEnabled(FEATURE_FLAGS.GLOBAL_DOWNLOADS, []));

  return (
    <FeatureFlagsContext.Provider
      value={{
        flagsLoaded,
        inDevelopment,
        inLocalEnv,
        enableSentry,
        screenshotMode,
        disableCcfLimit,
        mapboxPoc,
        maOpportunitiesTab,
        geologyFilter,
        pdfEmbedding,
        paHideCountriesWithNoAssets,
        smartReports,
        enableAssetReportPdfViewMode,
        feedbackFeature,
        globalDownloads,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

const useFeatureFlags = () => useContext(FeatureFlagsContext);

export { FeatureFlagsProvider, useFeatureFlags };
