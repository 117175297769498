export const VALUATIONS_CONTEXT = 'valuations';
export const ASSET_TEMPLATE_CONTEXT = 'asset-template';

export const VALUATIONS_GOOGLE_ANALYTICS_TAG = 'AssetOverview';
export const ASSET_TEMPLATE_GOOGLE_ANALYTICS_TAG = 'AssetPage';

export const REPORT_TYPES = {
  VALUATIONS_MODEL: 'report',
  PDF_REPORT: 'pdf_report',
  ENHANCED_EXCEL_REPORT: 'enhanced_report',
  ASSET_REPORT: 'enhanced_pdf_report',
  ASSET_REPORT_EMBED: 'enhanced_pdf_embed_report',
  ENHANCED_HTML_REPORT: 'enhanced_html_report',
};
