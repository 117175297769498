import PipelineNetworkThroughputDownload from '../../PipelineNetwork/PipelineNetworkThroughputDownload';
import PipelineNetworkThroughputGraphs from '../../../pages/OilAndGasMap/Info/graphs/pipelineNetworkThroughputGraphs';
import React from 'react';

export const NAME_PIPELINE_NETWORK_SUMMARY_ITEM = {
  label: 'Pipeline Network Name',
  accessor: 'name',
};

export const STATUS_PIPELINE_NETWORK_SUMMARY_ITEM = {
  label: 'Status',
  accessor: 'formattedStatus',
};

export const START_UP_PIPELINE_NETWORK_SUMMARY_ITEM = {
  label: 'Start Up Year',
  accessor: 'startUpYear',
};

export const LENGTH_KM_PIPELINE_NETWORK_SUMMARY_ITEM = {
  label: 'Length (km)',
  accessor: 'formattedLengthKm',
};

export const CAPACITY_PIPELINE_NETWORK_SUMMARY_ITEM = {
  label: 'Current Capacity',
  unit: (p) => (p?.formattedHydrocarbon === 'Oil' ? '(bbl/d)' : '(MMcf/d)'),
  accessor: 'formattedCurrentCapacity',
};

export const HYDROCARBON_PIPELINE_NETWORK_SUMMARY_ITEM = {
  label: 'Hydrocarbon',
  accessor: 'formattedHydrocarbon',
};

export const GRAPH_THROUGHPUT_PIPELINE_NETWORK_SUMMARY_ITEM = {
  type: 'graph',
  accessor: (p) =>
    p.id && p.hasThroughputs ? (
      <>
        <PipelineNetworkThroughputDownload pipelineNetwork={p} />
        <PipelineNetworkThroughputGraphs pipelineNetworkId={p.id} />
      </>
    ) : null,
};
