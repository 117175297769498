import {
  ABATEMENT_COSTS,
  ABATEMENT_COST_STACK_TYPE,
  FACILITY_NAME_ABATEMENT_AGGREGATE,
  costAggregateTypes,
  costGraphTypes,
} from '../utils';
import React, { createContext, useEffect, useMemo, useState } from 'react';

import { CCUS_GOOGLE_ANALYTICS_TAG } from '../../constants';
import { GRAPH_CCUS_QUERY } from '../operations';
import GraphControls from '../../../../components/Graphs/GraphControls';
import { cloneDeep } from 'lodash';
import { downloadXlsx } from '../../../../components/Downloads/helpers';
import { useQuery } from '@apollo/client';

const BarGraphContextCosts = createContext();

export const BarGraphProviderCosts = ({ children, filters }) => {
  const [openControlsModal, setOpenControlsModal] = useState(false);

  const [aggregate, setAggregate] = useState(FACILITY_NAME_ABATEMENT_AGGREGATE);
  const [graphType, setGraphType] = useState(ABATEMENT_COSTS);
  const [limit, setLimit] = useState(FACILITY_NAME_ABATEMENT_AGGREGATE.defaultLimit);
  const [sortDirection, setSortDirection] = useState(
    FACILITY_NAME_ABATEMENT_AGGREGATE.defaultSortDirection,
  );
  const [range, setRange] = useState({ x: [], y: [] });
  const [modalRange, setModalRange] = useState({ x: [], y: [] });
  const [showPlotlyLegend, setShowPlotlyLegend] = useState(true);

  const resetRange = () => {
    setRange({ x: [], y: [] });
    setModalRange({ x: [], y: [] });
  };

  const onRelayout = (e) => {
    const x = [e['xaxis.range[0]'], e['xaxis.range[1]']];
    const y = [e['yaxis.range[0]'], e['yaxis.range[1]']];
    setRange({ x, y });
  };

  const onGraphUpdate = () => {
    if (
      (range.x[0] !== modalRange.x[0] && range.x[1] !== modalRange.x[1]) ||
      (range.y[0] !== modalRange.y[0] && range.y[1] !== modalRange.y[1])
    ) {
      setModalRange(range);
    }
  };

  const variables = useMemo(
    () => ({
      aggregate: aggregate.name,
      graphType: graphType.value,
      filters,
      stackType: ABATEMENT_COST_STACK_TYPE.value,
      limit: parseInt(limit),
      sortDirection: sortDirection.value,
    }),
    [aggregate, graphType, filters, limit, sortDirection],
  );

  const { data, loading } = useQuery(GRAPH_CCUS_QUERY, {
    variables,
  });

  useEffect(() => {
    resetRange();
  }, [data]);

  const downloadCallback = (errorHandler) => {
    const path = 'ccus/export-bar-graph/';
    const downloadName = `CCUS_Abatement_Costs_Data.xlsx`;

    downloadXlsx(path, variables, downloadName, errorHandler);
  };

  return (
    <BarGraphContextCosts.Provider
      value={{
        downloadCallback,
        graphType,
        loading,
        modalRange,
        onGraphUpdate,
        onRelayout,
        range,
        setModalRange,
        setOpenControlsModal,
        setShowPlotlyLegend,
        showPlotlyLegend,
        showPlotlyLegendToggle: true,
        sortDirection,
        trace: cloneDeep(data?.graphCcus?.analytics || []),
        maxY: data?.graphCcus?.maxY || [],
        variables,
      }}
    >
      {children}
      <GraphControls
        aggregate={aggregate}
        aggregateTypes={costAggregateTypes}
        googleAnalyticsTag={CCUS_GOOGLE_ANALYTICS_TAG}
        graphType={graphType}
        limit={limit}
        open={openControlsModal}
        setAggregate={setAggregate}
        setGraphType={setGraphType}
        setLimit={setLimit}
        setOpen={setOpenControlsModal}
        setSortDirection={setSortDirection}
        sortDirection={sortDirection}
        types={costGraphTypes}
      />
    </BarGraphContextCosts.Provider>
  );
};

export const BarGraphConsumerLeft = BarGraphContextCosts.Consumer;

export default BarGraphContextCosts;
